import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { Linear, gsap, TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { slick } from "slick-carousel";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import { ScrollToPlugin } from 'gsap/src/ScrollToPlugin';
import { expoScale } from 'gsap/EasePack';
import { CustomEase } from 'gsap/CustomEase';
gsap.registerPlugin(ScrollToPlugin)	
gsap.registerPlugin(CustomEase)	
 
ScrollMagicPluginGsap(ScrollMagic, gsap);
//import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';

var controllerScrollPanel,
scrollSceneDefaultForward,
scrollSceneDefaultReverse,
windowWidth,
windowHeight,
resizeTimer;
	
/*const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
}
appHeight();*/

/*var lastY;
var currentY;
$('body').on('touchstart', (function(event) {
	var currentY = event.originalEvent.touches[0].clientY;
    lastY = currentY;
    //event.preventDefault();
}));
$('body').on('touchmove', (function(event) {
	var currentY = event.originalEvent.touches[0].clientY;

	if(!$('html').hasClass('is-unlocked')) {
		if($('body').hasClass('touching-up') && $('body').hasClass('scrolling-down')) {
			appHeight();
		}

		if($('body').hasClass('touching-down') && $('body').hasClass('scrolling-up')) {
			appHeight();
		}
	}	

	if (currentY > lastY) {
		$('body').removeClass('touching-down').addClass('touching-up');
	} else if(currentY < lastY) {
		$('body').removeClass('touching-up').addClass('touching-down');
	}

	lastY = currentY;
}));*/

// If the .fullpage container exists, set up the fullpage section animations

$('body').addClass('is-locked');

$( 'video' ).each(function(){
	$(this).on('loadeddata',function() {
		$(this).addClass('loaded');
	});
});

$( window ).on('load',function() {

	if ( $('.main-experience').length ) {

		initController();
		handleResize();
		scrollNav();
		$('body').removeClass('is-locked');
		$('.main-experience').removeClass('before-load');
	}
	
	$('.nasdaq-head').css('width','100%').css('width', '-='+getScrollbarWidth() +'px');

	$('body').removeClass('loading');
	
});



// Initialize the scrollMagic controller
function initController() {

	// Init new controller
	controllerScrollPanel = new ScrollMagic.Controller();

	// Change behaviour of controller to animate scroll instead of jump
	controllerScrollPanel.scrollTo(function (newpos,duration) {

		var durationNum = Number(duration);
		
		//$('html').addClass('is-locked');				
		$('.navigation__container').css("right", getScrollbarWidth() + "px");
		//$('.nasdaq-head').css('width','100%').css('width', '-='+getScrollbarWidth() +'px');

		if( $('html').hasClass('jump-scrolling') || $('html').hasClass('resizing') ) {
		} else {
			//rapid: CustomEase.create("custom", "M0,0 C0,0 -0.087,0.564 0.154,0.806 0.412,1.064 1,1 1,1 ")
			//sine: CustomEase.create("custom", "M0,0,C0,0,0.185,0.287,0.31,0.467,0.355,0.534,0.383,0.569,0.435,0.631,0.481,0.687,0.509,0.719,0.56,0.77,0.603,0.814,0.631,0.839,0.68,0.876,0.717,0.905,0.743,0.921,0.785,0.943,0.822,0.963,0.849,0.974,0.89,0.985,0.929,0.995,1,1,1,1")
			//Power0: CustomEase.create("custom", "M0,0,C0,0,1,1,1,1")
			//slowmo: CustomEase.create("custom", "M0,0,C0,0,0.023,0.173,0.045,0.276,0.05,0.301,0.058,0.319,0.07,0.34,0.077,0.355,0.085,0.37,0.1,0.375,0.352,0.46,0.586,0.52,0.875,0.612,0.891,0.617,0.904,0.623,0.915,0.634,0.928,0.648,0.936,0.664,0.945,0.683,0.955,0.707,0.96,0.725,0.965,0.751,0.981,0.846,1,1,1,1")
			/*if($('html').hasClass('is-scrolling')) {
				var tween = gsap.to($('html'), .6, {scrollTo: {y: newpos, autoKill:false}});
			} else {*/
				var tween = gsap.to($('html'), durationNum, {scrollTo: {y: newpos, autoKill:false, ease: CustomEase.create("custom", "M0,0,C0,0,0.023,0.173,0.045,0.276,0.05,0.301,0.058,0.319,0.07,0.34,0.077,0.355,0.085,0.37,0.1,0.375,0.352,0.46,0.586,0.52,0.875,0.612,0.891,0.617,0.904,0.623,0.915,0.634,0.928,0.648,0.936,0.664,0.945,0.683,0.955,0.707,0.96,0.725,0.965,0.751,0.981,0.846,1,1,1,1")}});
			//}
		
		}

	});

	// Init the forward and reverse scenes
	scrollPanelScenes();
}

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

windowWidth = $(window).width();
windowHeight = $(window).height();

// If window is resized, destroy controller and reset once resize has stopped
function handleResize() {

	var resizeThisStuff = debounce(function() {

		//only if actually resize, not scrolling up/down on ios phones

		if ( 
			($(window).height() - windowHeight) > 20 || 
			($(window).height() - windowHeight) < -20 || 
			($(window).width() - windowWidth) > 10 ||
			($(window).width() - windowWidth) < -10 
		) {

			$('html').addClass('resizing');
			//resizeTimer = setTimeout(doneResizing, 0);
			doneResizing();

			//update these so keeps updating after/if switch back to OG
			windowWidth = $(window).width();
			windowHeight = $(window).height();

		}

	}, 500);

	window.addEventListener('resize', resizeThisStuff);
	
	function doneResizing(){
		destroyScrollPanels();
		//clearTimeout(resizeTimer);
		reInitScrollPanels();
	}

}


// Destroy scroll panels
function destroyScrollPanels() {
	controllerScrollPanel.destroy();
	scrollSceneDefaultForward.destroy();
	scrollSceneDefaultReverse.destroy();

}


// Re-init scroll panels
function reInitScrollPanels() {
	controllerScrollPanel = null;
	scrollSceneDefaultForward = null;
	scrollSceneDefaultReverse = null;

	/*var myArray = ['red', 'blue', 'yellow', 'green','black','white','pink'];
	var randomItem = myArray[Math.floor(Math.random()*myArray.length)];
	$('body').css('color',randomItem);*/
	
	initController();
	$('html').removeClass('resizing');
	//scrollNav();
	$('body').removeClass('is-locked');
	$('.main-experience').removeClass('before-load');
}

var clickedOnScrollbar = function(mouseX){
	if( $(window).outerWidth() - getScrollbarWidth() <= mouseX ){
		return true;
	}
}
$(document).on('mousedown', function(event){
	if( clickedOnScrollbar(event.clientX) ){
		$('html').addClass('is-scrolling');
		if($('html').hasClass('is-locked')) {} else {
			$('.navigation__container').css("right", getScrollbarWidth() + "px");
			$('.nasdaq-head').css('width','100%').css('width', '-='+getScrollbarWidth() +'px');
		}
	}
});
$(document).on('mouseup', function(event){
	if( clickedOnScrollbar(event.clientX) ){
		$('html').removeClass('is-scrolling');
		if($('html').hasClass('is-locked')) {} else {
			$('.navigation__container').css("right", "0");
			$('.nasdaq-head').css('width','100%').css('width', '100%');
		}
	}
});

// The forward and reverse scenes
function scrollPanelScenes() {

	var thisSection;

	$('.navigation a').remove();

	//init3D();

	checkTop();

	$('body').addClass('scrolling-down');

	$('.slide-experience').each(function(index, elem) {

		var lastY;

		$(elem).not('.slick-initialized').slick({
			dots: true,
			arrows: false,
			edgeFriction: 0,
			infinite: false,
			//swipe: true,
			draggable: false,
			speed: 500,
			touchMove: false,
			useTransform: false,
			//verticalSwiping: true,
			//vertical: true
		});
		
		$(elem).on('beforeChange', (function(event) {
			$(elem).addClass('changing');
		})).on('afterChange', (function(event) {
			//init3D();
			setTimeout(function() {
				$(elem).removeClass('changing');
			},900);
		}));

		//for scrollbar click scrolling

		$(document).on('mousedown', function(event){

			if($('html').hasClass('is-locked')) {} else {
				if ( $(elem).closest('.main-experience__section').hasClass('before-current') ) {
					if( $(window).outerHeight()/2 > event.clientY ){
						$(elem).addClass('scrollbarred');
					}
				}
				if ( $(elem).closest('.main-experience__section').hasClass('current') ) {
					if( clickedOnScrollbar(event.clientX) ){

						$('html').addClass('is-locked');
						$('.navigation__container').css("right", getScrollbarWidth() + "px");
						$('.nasdaq-head').css('width','100%').css('width', '-='+getScrollbarWidth() +'px');

						$(elem).addClass('scrollbarred');

						//up
						if( $(window).outerHeight()/2 > event.clientY ){
							if( ! $(elem).find('.slick-slide').first().hasClass('slick-current')) {
								//event.preventDefault();
								$(elem).slick('slickPrev');		
							} else {
								$('html').removeClass('is-locked');
								/*$('.navigation__container').css("right", '0px');
								$('.nasdaq-head').css('width','100%');*/

								$(elem).removeClass('scrollbarred');
							}
						}

						//down
						if( $(window).outerHeight()/2 <= event.clientY ){
							if( ! $(elem).find('.slick-slide').last().hasClass('slick-current')) {
								//event.preventDefault();
								$(elem).slick('slickNext');	
							} else {
								$('html').removeClass('is-locked');
								/*$('.navigation__container').css("right", '0px');
								$('.nasdaq-head').css('width','100%');*/

								$(elem).removeClass('scrollbarred');
							}

						}
							
					}
				}	
			}	
		});

		//for keypad scrolling

		$('body').on('keydown', (function(event) {

			if($('html').hasClass('is-locked')) {} else {

				$('.main-experience__section').removeClass('scrollbarred');
				
				if ( $(elem).closest('.main-experience__section').hasClass('current') ) {
					if(event.key == 'ArrowDown') {

						if( ! $(elem).find('.slick-slide').last().hasClass('slick-current')) {
							event.preventDefault();
							$(elem).slick('slickNext');		
						}

					}
					if(event.key == 'ArrowUp') {

						if( ! $(elem).find('.slick-slide').first().hasClass('slick-current')) {
							event.preventDefault();
							$(elem).slick('slickPrev');		
						}
						
					}
				}
			}	

		}));
		
		//for mousewheel, touchpad, and finger scrolling

		var lastY;
		var currentY;

		$(elem).on('touchstart', (function(event) {
			var currentY = event.originalEvent.touches[0].clientY;
		    lastY = currentY;
		    //event.preventDefault();
		}));

		$(elem).on('touchmove wheel', (function(event) {

			$('.main-experience__section').removeClass('scrollbarred');
			
			if(event.type == 'touchmove') {
				var currentY = event.originalEvent.touches[0].clientY;
			}

			if($(elem).hasClass('changing')) {
				event.preventDefault();
			} else {

				if($(elem).closest('.main-experience__section').hasClass('current')) {

					if (event.originalEvent.deltaY < 0 || currentY > lastY) {
						if ($(elem).find('.slick-slide').first().hasClass('slick-current') ) {} else {
							event.preventDefault();
							if($('html').hasClass('is-locked')) {} else {
								$(this).slick('slickPrev');	
							}				
						}
					} else if(event.originalEvent.deltaY > 0 || currentY < lastY) {
						if( $(elem).find('.slick-slide').last().hasClass('slick-current') ) {} else {
							event.preventDefault();
							if($('html').hasClass('is-locked')) {} else {
								$(this).slick('slickNext');		
							}			
						}
					}

				}
			}	
					
			if(event.type == 'touchmove') {
				lastY = currentY;
			}
				
		}));

	});

	$('.main-experience__section').each(function(index, elem) {
		
		var $scrollPanel = $(elem);
		var forwardScrollPos = $scrollPanel.offset().top;

		var title = $(this).attr('data-title');
		var navigation = $(this).attr('data-navigation');
		var section = $(this).attr('data-section');
		var titleStripped = title.toLowerCase().replace(/\s/g, '-');

		if (navigation === 'nav') {
			$('.navigation').append('<a href="#'+titleStripped+'" class="'+titleStripped+'">'+title+'</a>');
		}

		$(this).attr('id',titleStripped);
		thisSection = $('.main-experience__section[data-title="'+titleStripped+'"]');

		scrollSceneDefaultForward = new ScrollMagic.Scene({
			offset: 35, // Number of pixels user can scroll before panel snaps into place
			triggerElement: elem,
			triggerHook: 1,// Trigger this scene when top of panel enters view
		})
		.on('start', function(event) {

			forwardScrollPos = $scrollPanel.offset().top;

			checkTop();

			if (event.scrollDirection == 'FORWARD') {

				if( $('body').hasClass('scrolling-up') ) {
					//appHeight();
				}

				$('body').removeClass('scrolling-up').addClass('scrolling-down');

				$('.main-experience').attr('class', 'main-experience '+section+' '+titleStripped);

				/*if(windowWidth > 500) {

					controllerScrollPanel.scrollTo(forwardScrollPos, 0 ); // If direction is forward, trigger scrollTo

				} else {*/
					
					controllerScrollPanel.scrollTo(forwardScrollPos, .45 ); // If direction is forward, trigger scrollTo

				//}	
				
				$('.main-experience__section').removeClass('current');
				$('.main-experience__section').removeClass('before-current');
				$('.main-experience__section').removeClass('after-current');
				
				$(elem).prev().addClass('before-current');
				$(elem).next().addClass('after-current');
				$(elem).addClass('current');

			} else if (event.scrollDirection == 'REVERSE') {
				$('body').addClass('scrolling-up').removeClass('scrolling-down');
			}
		})
		.on('enter', function(event) {
			if($('.main-experience').hasClass('before-load')) {} else {
				$('html').addClass('is-locked');
				$('.navigation__container').css("right", getScrollbarWidth() + "px");
				$('.nasdaq-head').css('width','100%').css('width', '-='+getScrollbarWidth() +'px');
			}
			setTimeout(function() {
				if($(elem).find('.slide-experience').hasClass('scrollbarred')) {} 
				else if($('html').hasClass('is-scrolling')) {
					$('html').removeClass('is-locked'); 
				} else {
					$('html').removeClass('is-locked'); 
					//$('html').removeClass('jump-scrolling');
					$('.navigation__container').css("right", '0px');
					$('.nasdaq-head').css('width','100%');
				}	
			},900);
		})
		.addTo(controllerScrollPanel);
	})


	// Create scenes for panels, when scrolling reverse
	$('.main-experience__section:nth-child(n+2)').each(function(index, elem) {
		
		var $scrollPanel = $(elem);
		var reverseScrollPos = $scrollPanel.prev().offset().top;

		var reverseTitle = $scrollPanel.prev().attr('data-title');
		var reverseTitleStripped = reverseTitle.toLowerCase().replace(/\s/g, '-');
		var reverseSection = $scrollPanel.prev().attr('data-section');

		/*var title = $(this).attr('data-title');
		var navigation = $(this).attr('data-navigation');
		var section = $(this).attr('data-section');
		var titleStripped = title.toLowerCase().replace(/\s/g, '-');*/

		scrollSceneDefaultReverse = new ScrollMagic.Scene({
			offset: -50, // Number of pixels user can scroll before panel snaps into place
			triggerElement: elem,
			triggerHook: 0,// Trigger this scene when bottom of panel enters view
		})
		.on('start', function(event) {

			checkTop();

			reverseScrollPos = $scrollPanel.prev().offset().top;

			if (event.scrollDirection == 'FORWARD') {

				$('body').addClass('scrolling-down').removeClass('scrolling-up');

			} else if (event.scrollDirection == 'REVERSE') {

				if( $('body').hasClass('scrolling-down') ) {
					//appHeight();
				}

				$('body').removeClass('scrolling-down').addClass('scrolling-up');

				$('.main-experience').attr('class', 'main-experience '+reverseSection+' '+reverseTitleStripped);
				
				/*if(windowWidth > 500) {

					controllerScrollPanel.scrollTo(reverseScrollPos, 0 ); // If direction is reverse, trigger scrollTo

				} else {*/

					controllerScrollPanel.scrollTo(reverseScrollPos, .45 ); // If direction is reverse, trigger scrollTo

				//}

				$('.main-experience__section').removeClass('current');
				$('.main-experience__section').removeClass('before-current');
				$('.main-experience__section').removeClass('after-current');

				$(elem).prev().prev().addClass('before-current');
				$(elem).addClass('after-current');
				$(elem).prev().addClass('current');

			}
		})
		.on('leave', function(event) {
			$('html').addClass('is-locked');
			$('.navigation__container').css("right", getScrollbarWidth() + "px");
			$('.nasdaq-head').css('width','100%').css('width', '-='+getScrollbarWidth() +'px');
			setTimeout(function() {
				if($(elem).prev().find('.slide-experience').hasClass('scrollbarred')) {} 
				else if($('html').hasClass('is-scrolling')) {
					$('html').removeClass('is-locked'); 
				} else {
					//console.log('removedstuff');
					$('html').removeClass('is-locked'); 
					//$('html').removeClass('jump-scrolling');
					$('.navigation__container').css("right", '0px');
					$('.nasdaq-head').css('width','100%');
				}
			},900);

		})
		.addTo(controllerScrollPanel);
	});

}	

function scrollNav() {
// change behaviour of controller to animate scroll instead of jump

	//  bind scroll to anchor links
	$(document).on("click", "a[href^='#']", function (event) {

		var id = $(this).attr("href");

		if ($(id).length > 0) {
			event.preventDefault();

			if($('html').hasClass('is-locked') || $('html').hasClass('jump-scrolling')) {} else {
			
				$('html').addClass('jump-scrolling');
				$('html').addClass('is-locked');
				$('.navigation__container').css("right", getScrollbarWidth() + "px");
				$('.nasdaq-head').css('width','100%').css('width', '-='+getScrollbarWidth() +'px');

			 	TweenMax.killTweensOf( $('html') );

			 	// controllerScrollPanel.scrollTo($(id).offset().top, 1 );
			 	var animateSpeed;
			 	/*if(windowWidth > 500) {
			 		animateSpeed = 50;
			 	} else {*/
			 		animateSpeed = 300;
			 	//}

				// trigger scroll
				$('html').animate({
					
					scrollTop: $(id).offset().top

				},animateSpeed, 'swing', function(){
				
					$('html').removeClass('is-locked');
					$('.navigation__container').css("right", "0px");
					$('.nasdaq-head').css('width','100%');

					setTimeout(function() {
						$('html').removeClass('jump-scrolling');
					},400);
				
				});

				// if supported by the browser we can even update the URL.
				/*if (window.history && window.history.pushState) {
					history.pushState("", document.title, id);
				}*/

			}

		}

	});	
}


function getScrollbarWidth() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);        

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll - .5;
}

/*function init3D() {
	var $workItem = $(".mousemove");

	$workItem.each(function() {
		$(this).data("xPos", $(this).offset().left);
		$(this).data("yPos", $(this).offset().top);
		$(this).data("itemWidth", $(this).width());
		$(this).data("itemHeight", $(this).height());
	});

	$workItem.mousemove(function(e) {
		var xPos = $(this).data("xPos");
		var yPos = $(this).data("yPos");
		var mouseX = e.pageX;
		var mouseY = e.pageY;
		var left = mouseX - xPos;
		var top = mouseY - yPos;
		var origin = "center center -100";
		var xPerc =
			(left - $(this).data("itemWidth") / 2) / $(this).data("itemWidth") * 100;
		var yPerc =
			(top - $(this).data("itemHeight") / 2) / $(this).data("itemHeight") * 100;

		TweenMax.to($(this), 1.1, {
			rotationX: 0.1 * yPerc,
			rotationY: -0.1 * xPerc,
			transformOrigin: origin
		});
	});

	$workItem.on("mouseleave", function() {
		TweenMax.to($(this), 1.1, {
			rotationX: 0,
			rotationY: 0,
			transformOrigin: origin
		});
	});
}*/

function checkTop() {
	if($(window).scrollTop() < 130) {
		$('body').addClass('top');
	} else {
		$('body').removeClass('top');
	}
}