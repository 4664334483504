function toggleMenu(){
	this.classList.toggle('active');
	if (this.classList.contains('active')) {
	    document.querySelector('.menubar').style.display = "block";
	    document.querySelector('.nasdaq-head').setAttribute('data-toggle','open');
	} else {
	    document.querySelector('.menubar').style.display = "none";
	    document.querySelector('.nasdaq-head').setAttribute('data-toggle','closed');
	}
}
document.querySelector('.mobilemenu').addEventListener('click', toggleMenu );